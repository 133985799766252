@import url('https://fonts.googleapis.com/css2?family=Inter&family=Roboto:wght@400;500;700&display=swap');
@font-face {
  font-family: 'Times New Roman';
  src: url('./../assets/fonts/Times New Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Color roots */
:root {
  --white-color: #ffffff;
  --black-color: #4D4D4D;
  --primary-color: #fcaf17;
  --grey-color: #696871;
}
body{
  /* font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji" !important; */
  font-family: 'Roboto' !important;
    font-size: 16px;
  
}

.mt-20{
  margin-top: 20px !important;
}
.mr-10{
  margin-right: 10px;
}
.mlr-5p{
  margin-right: 5% !important;
}

.clr-red{
  color: red !important;
}

.crsr-pointer{
  cursor: pointer !important;
}

.table-timesheet {
  border: 1px solid #c8c8cd;
  border-collapse: collapse;
  background-color: white;
  padding-left:30px !important;
  padding-right:30px !important;
  font-weight: bold !important;
  font-size: 13px;
}

.tr-fnt-size{
  font-weight: bold !important;
  font-size: 13px;
}

.fnt-size-13{
  font-size: 13px;
}

.clr-gray{
  color: gray !important;
  
}

.bg-lght-gray{
  background-color: #f1f1f1 !important;
}
.bg-white
{
  background-color: white !important;
}

.input-style{
  background-color: #f1f1f1;
  border-color: #f1f1f1 !important;
  border: 0px !important;
  border-radius: 5px;
  padding: 5px 35px 5px 5px;
  box-sizing: border-box;
  height: 34px;
  width: 240px !important
}

.input-style5{
  background-color: #f1f1f1;
  border-color: #f1f1f1 !important;
  border: 0px !important;
  border-radius: 5px;
  padding: 5px 2px 5px 5px;
  box-sizing: border-box;
  height: 34px;
  width: 240px !important
}

.input-style4{
  background-color: #f1f1f1;
  border-color: #f1f1f1 !important;
  border: 0px !important;
  border-radius: 5px;
  padding: 5px 35px 5px 5px;
  box-sizing: border-box;
}




.input-style3{
  background-color: #f1f1f1;
  border-color: #f1f1f1 !important;
  border: 0px !important;
  border-radius: 5px;
  padding: 5px 35px 5px 5px;
  box-sizing: border-box;
  height: 34px;
  width: 100% !important;
  float: left !important;
}

.brdr-0{
  border: 0px !important;
}

select {
  width: 150px;
  font-size: 16px;
  border: 1px solid #CCC;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
  padding-top: 1px !important ;
}

.save-btn{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 61px;
    max-width: 138px !important;
    height: 36px;
    background-color: #fdba29cf !important;
    color: black !important;

    border: 1px solid #ffb614cf !important;
    box-sizing: border-box;
    border-radius: 3px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
}
.submit-btn{
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  min-width: 200px !important;
  max-width: 200px !important;
  height: 36px;
  background-color: darkgreen !important;
  color: white !important;

  border: 1px solid darkgreen !important;
  box-sizing: border-box;
  border-radius: 3px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  display: block;
  margin: auto;
  text-transform: none !important;
  box-shadow: none !important;
}

.table-input-box{
  /* background-color: #f1f1f1 !important; */
  width: 130px !important;
  /* margin-left: 23%; */
  text-align: center;
  height: 33px !important;
  padding-top: 3px;
  padding-bottom: 4px;
}
@media screen and (max-width:1300px){
  .table-input-box{
    /* background-color: #f1f1f1 !important; */
    width: 130px !important;
    /* margin-left: 23%; */
    text-align: center;
    height: 33px !important;
    padding-top: 3px;
    padding-bottom: 4px;
  }

}
@media screen and (max-width:1100px){
  .table-input-box{
    /* background-color: #f1f1f1 !important; */
    width: 130px !important;
    /* margin-left: 23%; */
    text-align: center;
    height: 33px !important;
    padding-top: 3px;
    padding-bottom: 4px;
  }

}
/* .signatureCanvas{
  width:32% !important;
  height:120px !important;
  background-color: #f1f1f1;
  border-radius: 7px !important;

} */

.z-index10{
  position: absolute;
  right: 12rem;
  z-index: 20px;
  bottom: -1500px;
}

.border-box {
  box-shadow: 1px 2px 9px #c7ccb2;
  margin: 3em;
  padding: 1em;
  border-radius: 10px !important;


}
.top-border-box{
  box-shadow:  0px 10px 40px rgb(0 0 0 / 10%) !important;
  background-color: white !important;
  border-radius: 10px !important;

}

.wdth-240{
  width: 240px !important
}

.save-btn-disabled{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 61px;
    max-width: 138;
    height: 36px;
    background-color: #f1f1f1 !important;
    color: black !important;

    border: 1px solid #f1f1f1 !important;
    box-sizing: border-box;
    border-radius: 3px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
}

.txt-alg-cntr{
  text-align: center !important;
}
.txt-alg-cnt2{
  justify-content: center !important;
}
.alert {
  border: 1px solid transparent;
 }

.success-msg{
  text-align: center;
    color: green
}
.fail-msg{
  text-align: center;
    color: red;
}
.warn-msg{
  text-align: center;
    color: #ffb614cf;

}
.save-button{
  float: right;
  margin-right: 32px !important;
  margin-top: 40px;
}

.mr-top-155{
  margin-top: -155px !important
}

.active-link{
    color: #fdba29cf;
    margin-top: 7px;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}
.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 85px;
  height: 30px;
  background: grey;
  color: #fff;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  top: -21px !important
}
.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 22px;
  height: 20px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}
.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}
.react-switch-label:active .react-switch-button{
  width: 60px;
}
.react-switch-label1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 85px;
  height: 30px;
  background: green !important;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  top: -21px !important
}
.react-switch-label1 .react-switch-button1 {
  content: '';
  position: absolute;
  top: 5px;
  left: 4px;
  width: 22px;
  height: 20px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}
.react-switch-checkbox:checked + .react-switch-label1 .react-switch-button1 {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}
.react-switch-label1:active .react-switch-button1 {
  width: 60px;
}

.switch-toggle-no{
  position: absolute !important;
  z-index: 1 !important;
  margin-left: 31px !important;
  margin-top: 1px !important;
  color: white !important;
}
.switch-toggle-yes{
  position: absolute !important;
  z-index: 1 !important;
  margin-left: 15px !important;
  margin-top: 3px;
  color: white !important;
}

.rmdp-calendar{
  width: 80 px !important;
}
.rmdp-top-class{
  width: 180px !important;
  display: grid !important
}
.rmdp-day-picker {
  display: none !important;
}

.rmdp-arrow{
  height: 15px !important;
  width: 15px !important;
  border-width: 0 2px 2px 0;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: black;
  border-right-color: black;
  border-bottom-color: black;
  border-left-color: black;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  margin-top: 2px !important;
}

.rmdp-week, .rmdp-ym{
  display: none !important;
}

.rmdp-wrapper {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.font-11px{
    font-size: 11px !important;
  }


.dLyRAV{
  margin-bottom: 5px !important;
}


.input-text-cstm {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #4D4D4D !important;
  font-family: 'Roboto' !important;
}
.rdt_TableHead{
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 24px !important;
  color: #4D4D4D !important;
  font-family: 'Roboto' !important;

}

.cs-dt-customer-feature:hover {
  cursor: pointer;
  text-decoration: underline;
}

.dashboard-table .tag2feature {
  display: inline-flex;
  padding: 1% 1% 0% 3%;
  margin-left: 5px;
  background-color: #231b12 !important;
  border-radius: 5px;
  word-wrap: none !important;
  width: fit-content !important;
  font-size: 10px;
  color: #ffffff;
  text-transform: capitalize;
}

.cstm-btn-style{
  height: 80%  !;
  font-size: 90% !important;
}
.view-btn{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 80px;
    max-width: 80px;
    height: 36px;
    background-color: #ffffff !important;
    color: #0c0c0c !important;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;
    border: 1px solid gray;

}
.view-btn:hover{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 80px;
    max-width: 80px;
    height: 36px;
    background-color: #ffffff !important;
    color: #0c0c0c !important;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;
    border: 1px solid gray;

}
.checkActive{
  color: green !important;
}

.nav-selcted{
  /* font-weight: 700 !important; */
  color: #fcaf17;
  text-decoration: none;
  
}

.close-btn{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 90px !important;
    max-width: 90px,!important;
    height: 36px;
    background-color: gray !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;

}

.close-btn:hover{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 90px !important;
    max-width: 90px,!important;
    height: 36px;
    background-color: gray !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;

}

.gMliOo{
  /* width: 7% !important; */
  margin-top: -5px !important;

}
.jaZsat{
  width: 18% !important;
  height: 14px !important;
  padding-top: 8px !important;
  margin-top: 3px !important;
}
@media screen and (max-width:480px){
  .gMliOo{
    width: 30% !important;
  }


}

.bueHSC{
  margin-top: 5px !important;
}

.signature-style{
float: left !important;
margin-left: 18% !important;
text-align: center !important;
/* font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji" !important; */
color: gray !important;
}

.clear-btn{
  /* margin-left: 36%; */
  cursor: pointer;
    font-size: 12px;
    color: blue;
}


@media screen and ( max-width:1520px){
  .clear-btn{
    /* margin-left: 33% !important; */
    cursor: pointer;
      font-size: 12px;
      color: blue;
  }
} 
@media screen and ( max-width:1370px){
  .clear-btn{
    /* margin-left: 24% !important; */
    cursor: pointer;
      font-size: 12px;
      color: blue;
  }
}

@media screen and (max-width:1280px){
  .clear-btn{
    /* margin-left: 22% !important; */
    cursor: pointer;
      font-size: 12px;
      color: blue;
  }

}

.doc-wdth{
  width: 80% !important;
  position: relative !important;
  overflow: scroll !important;
}
@media screen and (max-width:1400px){
  .doc-wdth{
    width: 95% !important;
    position: relative !important;
    overflow: scroll !important;
  }

}

@media screen and (max-width:1250px){
  .clear-btn{
    /* margin-left: 18% !important; */
    cursor: pointer;
      font-size: 12px;
      color: blue;
  }

}
@media screen and (max-width:1170px){
  .clear-btn{
    /* margin-left: 16% !important; */
    cursor: pointer;
      font-size: 12px;
      color: blue;
  }

}

.save-signature-btn{
 margin-left: 6px;
 font-size: 15px;
}

.mr-right{
  margin-right: 50px !important;
}

.add-sheet-btn{
  text-align: right;
    margin-right: 10px !important;
    margin-top: 15px;
    margin-bottom: -30px;
    color: gray;
}

.mrg-bt-60{
  margin-bottom: 60px !important;
}

.wdth-25{
  width: 25% !important;

}

.manager-name{
  text-align: center !important;
    margin-top: 25px !important;
    margin-left: 10px !important
    ;
}

.mr-l{
  margin-left: 5px !important;
}

.input-style2{
  background-color: #f1f1f1;
  border-color: #f1f1f1 !important;
  border: 0px !important;
  border-radius: 5px;
  padding: 5px 35px 5px 5px;
  box-sizing: border-box;
  height: 34px;
  width: 80% !important;
  float: left !important;
  margin-left: 18% !important;
}


.rmdp-arrow-container:hover {
  background-color: inherit !important;
  box-shadow: inherit !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid black !important;
  border-width: 0 2px 2px 0 !important;
}


/* ###### */
.header__user {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  cursor: pointer;
}
.logo {
  width: 145px;
}


.user__img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.header__nav {
  flex: 1 1 auto;
}

.nav__list {
  display: -webkit-box;
  align-items: center;
  justify-content: space-between;
  /* gap: 3%; */
  width: min(100%, 570px);
  margin-inline: auto;
  margin-top: 17px !important
}

.nav__list-item__link {
  position: relative;
  font-size: 16px;
  color: var(--grey-color);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
/* 
.nav__list-item__link:hover {
  color: #fcaf17;
}

.nav__list-item__link:hover::after {
  bottom: -10px;
  z-index: 0;
  visibility: visible;
  opacity: 1;
}

.nav__list-item__link::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #fcaf17;
  position: absolute;
  bottom: -15px;
  left: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
} */

.nav__list-item__link.active {
  color: #fcaf17;
}

.nav__list-item__link.active::after {
  bottom: -10px;
  z-index: 0;
  visibility: visible;
  opacity: 1;
}


.header {
  width: 100%;
  background: var(--white-color);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}

.header__content {
  width: min(100% - 15px, 1210px);
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}

li,ol,ul{list-style:none}

img{vertical-align:top}


.nav_header{
 margin-top: -12px;
 height: 120px;
}

header__nav{flex:1 1 auto}


NavLink{
  width: fit-content !important;
}

.user__name{
  margin-top: 17px !important;
}
.header__user-menu {
  position: absolute;
  right: 11%;
  top: calc(100% - 23px);
  width: 132px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  z-index: 1;
  opacity: 1;
  visibility: visible;
}

.user-menu__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.user-menu__list-item {
  width: 100%;
}

.user-menu__list-item:not(:last-child)::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: var(--grey-color);
  margin: 5px 0;
}

.user-menu__list-item a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: var(--black-color);
  opacity: 0.5;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
}

.user-menu__list-item a svg path {
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
}

.user-menu__list-item a:hover {
  color: #fcaf17;
}

.user-menu__list-item a:hover svg path {
  fill: #fcaf17;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}




.hover-underline-animation {
  text-decoration: none !important;  

  display: inline-block;
  position: relative;
  color:gray;
}

.hover-underline-animation:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #fcaf17 !important;
  position: absolute;
  bottom: -3px;
  left: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
}

.hover-underline-animation:hover:after {
  bottom: -3px;
  z-index: 0;
  visibility: visible;
  opacity: 1;
}
a:hover {
  color: #fcaf17 !important;
}

div.nav-hover:hover {
  color: #fcaf17 !important;
}

.menu-btn{
  border: NONE !important;
    background: white !important;
}

.fnt-24{
  font-size: 24px !important;
}
.menu-popup-top{
  top: 10% !important;
  cursor: pointer;
}
.popup-content{
  /* top: 94px !important; */
  width: 155px !important;
}

.popup-content2{
  top: 94px !important;
  /* width: 152px !important; */
}


.crsr-pointer-menu {
  cursor: pointer !important;
  border-bottom: 1px lightgray solid;
  margin-top: 10px;
  color: gray;
  /* text-align: center !important; */
  
}
.crsr-pointer-menu:hover{
  color:  #fcaf17;
  
}

.mar-br-5{
  margin-bottom: 5% ;
  margin-right:  5%;
}
.m-top-40{
  margin-top: 40px !important;
}
.width-70{
  width: 70% !important;
}

.width-75{
  width: 75% !important;
}


.document-wifth{
  width:70% !important;
}
@media screen and (max-width:1700px){
  .document-wifth{
    width:75% !important;

}

  }
.width-73{
  width: 73% !important;
}
.width-65{
  width: 65% !important;
}
.width-25{
  width: 25% !important;
}

@media screen and (max-width:1490px){
 .width-70{
  width:80% !important;
 }
 
 .width-57{
  width: 57% !important;
}
}


.m-l-34{
  margin-left: -34px !important;
}

.width-15{
  width: 15% !important;
}

.height-100{
  height: 100% !important;
}


.upload-btns{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    max-width: 110px;
    height: 36px;
    background-color: #fdba29cf !important;
    color: black !important;
    text-align: center !important;
    border: 1px solid #ffb614cf !important;
    box-sizing: border-box;
    border-radius: 3px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
}

.upload-btns:hover{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    max-width: 110px;
    height: 36px;
    background-color: #e9a719cf !important;
    color: black !important;
    text-align: center !important;
    border: 1px solid #ffb614cf !important;
    box-sizing: border-box;
    border-radius: 3px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
}



/* ---------------------upload Form---------------------------- */
.main__upload {
  width: 50%;
  margin: 0 auto 40px;
}

.upload__sector {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
  border: 1px dashed transparent;
  width: min(100% - 15px, 530px);
  margin: 0 auto;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: background-color 0.3s, padding 0.3s, border-color 0.3s;
  -webkit-transition: background-color 0.3s, padding 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, padding 0.3s, border-color 0.3s;
  -ms-transition: background-color 0.3s, padding 0.3s, border-color 0.3s;
  -o-transition: background-color 0.3s, padding 0.3s, border-color 0.3s;
}

.uploaded__file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  background: url("../assets/icons/uploaded-file.png") no-repeat center left 24px/16px, url("../assets/icons/white-arrow-down.png") no-repeat center right 24px/8px, #2c2c2c;
  color: white;
  width: 286px;
  margin: 118px 0 15px;
  padding: 24px 24px 24px 63px;
  filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.25));
  -webkit-filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.25));
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.upload__sector.active {
  background: url("../assets/icons/feather_upload-cloud.png") no-repeat center top 50px/48px, rgba(252, 175, 23, 0.05);
  border-color: #fdba29cf;
  cursor: pointer;
}

.upload__sector:hover {
  background: url("../assets/icons/feather_upload-cloud.png") no-repeat center top 50px/48px;
  border-color: #fcaf17;
  cursor: pointer;
}

.sector__title {
  font-size: 0;
  color: var(--black-color);
  height: 0;
  margin: 0 0 12px;
  text-align: center;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.upload__sector.active .sector__title,
.upload__sector:hover .sector__title {
  font-size: 14px;
  height: 100%;
  margin: 118px 0 12px;
  transition: all 0.3s;
}

.sector__formats {
  font-size: 0;
  color: var(--black-color);
  opacity: 0.4;
  text-align: center;
  margin-bottom: 20px;
  height: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.upload__sector.active .sector__formats,
.upload__sector:hover .sector__formats {
  font-size: 12px;
  height: 100%;
}

.upload__button {
  position: relative;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white !important;
  background-color: #fcaf17;
  padding: 10px 30px 10px 15px;
  border-radius: 5px;
  border: none !important;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.upload__sector.active .upload__button,
.upload__sector:hover .upload__button {
  margin: 50px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.upload__button.active {
  background: url("../assets/icons/upload-arrow.png") no-repeat center right 15px/8px, #fcaf17;
}

.upload__button:hover {
  background-color: #da9813;
  cursor: pointer;
}

.upload__sector input {
  display: none;
}

.page__title {
  font-size: 20px;
  color: var(--black-color);
  margin-bottom: 20px;
  text-align: center !important;
}

.main__container {
  padding: 50px 0 55px;
  align-items: center !important;
  background: #fff !important ;
  display: flex !important;
  flex-direction: column !important;
}

.page__under-title__text {
  font-size: 14px;
  opacity: 0.8;
  color: var(--grey-color);
  margin-bottom: 20px;
}

/* ----------------------------------------------upload------------------------------------------- */



.upload-process {
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.upload-process img{
  width: 100px;
  align-self: center;
  margin: 33px 0 33px;
}
.upload-process__title {
  font-size: 14px;
  margin-bottom: 30px;
}

.upload-process__file {
  display: flex;
  align-items: center;
  margin-bottom: 116px;
}

.upload-process__file img {
  width: 18px;
  margin: 0 20px 0 0;
}

.upload-process__progress {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.progress__file-prop {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress__title {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.progress__size {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.7);
}

.progress-bar {
  width: 100%;
  height: 15px;
  background-color: #fdd78b;
  border-radius: 3px;
  overflow: hidden;
}


.verified__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.verified__container img {
  width: 112px;
  margin-bottom: 16px;
}
.verified__title {
  /* font-family: "Helvetica"; */
  font-family: 'Roboto' !important;

  font-size: 20px;
  margin-bottom: 16px;
  text-align: center !important;
}
.verified__text {
  /* font-family: "Helvetica"; */
  font-family: 'Roboto' !important;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 32px;
}
.verified__btn {
  background: #fcaf17;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  /* font-family: "Helvetica"; */
  font-family: 'Roboto' !important;

  font-size: 14px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none !important;
  padding: 12px 24px;
  margin-bottom: 16px;
  transition: all 0.3s;
}
.verified__btn:hover {
  background: #df9606;
  color: white !important;
}


.main__steps {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
}

.steps__wrapper {
  width: 86px !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: default;
}

.steps__wrapper:nth-child(1)::after,
.steps__wrapper:nth-child(2)::after,
.steps__wrapper:nth-child(3)::after {
  content: "";
  width: 70px;
  height: 1px;
  background: #fdd78b;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(20%);
  -webkit-transform: translateX(20%);
  -moz-transform: translateX(20%);
  -ms-transform: translateX(20%);
  -o-transform: translateX(20%);
}

.steps__wrapper:nth-child(4)::before,
.steps__wrapper:nth-child(2)::before,
.steps__wrapper:nth-child(3)::before {
  content: "";
  width: 70px;
  height: 1px;
  background: #fdd78b;
  position: absolute;
  top: 25%;
  right: 50%;
  transform: translateX(-20%);
  -webkit-transform: translateX(-20%);
  -moz-transform: translateX(-20%);
  -ms-transform: translateX(-20%);
  -o-transform: translateX(-20%);
}

.steps__ball {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: #fdd78b;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.steps__wrapper.active .steps__ball {
  background: #fcaf17;
}

.steps__wrapper.active::after,
.steps__wrapper.active::before {
  background: #fcaf17;
}

.question-box{
  width: 100% !important;
  height: 110px !important;
  font-size: 20PX;
  color: rgb(88, 87, 87);
  border-radius: 5px !important;
}

.fnt-25{
  font-size: 25px !important;
}
.send-btn{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 90px;
    height: 36px;
    background-color: #fcaf17 !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;

}
.wdth-90px{
  width: 90px !important;
}

.height-400{
  max-height: 400px !important
  ;
}

.content-wrapper {
  background-color: white !important;
}
.register-heading {
  font-weight: 500;
  font-size: 30px;
  font-style: normal;
  line-height: 45px;
}

.inpt-box {
  background: #d8d8e4;
  opacity: 0.5;
  border: 1px solid #a6acbe;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: gray;
  height: 50px;
}
.mt15 {
  margin-top: 15px !important;
}
.form-label {
  color: #221b13;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.btn-sky2 {
  padding-left: 16px;
  padding-right: 16px;
  min-width: 100px;
  height: 40px;
  background-color: #fdba29cf !important;
  border-color: #fdba29cf !important;
  color: #000000 !important;
  margin: auto;
  display: block;
  border-radius: 8px;
  font-size: 16px !important;
  text-transform: none;
}
.mt30 {
  margin-top: 30px !important;
}

.mt16{
  margin-top: 16px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}
.btn-fnt {
  font-size: 16px;
  line-height: 24px;
  text-transform: none !important;
  font-weight: 500;
}

.visualBack {
  background-color: #efeded !important;
}

.visualBack2 {
  background-color: #ffeece !important;
}

.brdr-radius-3 {
  border-radius: 3px !important;
}
.brdr-radius-15 {
  border-radius: 15px !important;
}

.cstm-inpt-text {
  font-family: 'Roboto' !important;

  /* font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji" !important; */
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;

  color: #999896 !important;
}

.top402 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 100px !important;
}

.top401 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 240px !important;
}

.min-hgth-200 {
  height: 200px !important;
}
.min-hgth-240 {
  height: 240px !important;
}
.min-hgth-200 {
  height: 200px !important;
}


.pt10{
  padding-top: 10px !important;
}
.pt15{
  padding-top: 15px !important;
}
.pt13{
  padding-top: 13px !important;
}
.pt21{
  padding-top: 21px !important;
}
.pl20{
  padding-left: 20px !important;
}
.pr20{
  padding-right: 20px !important;
}
.pl5{
  padding-left: 5px !important;
}

.ovrview-heading {
  font-family: 'Roboto' !important;

  /* font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji" !important;; */
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 32px !important;
  line-height: 48px !important;

  color: #000000 !important;
}

.machines__selects {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 20px;
  height: 100% !important;
}

.search_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 10px;
  min-width: 170px;
  text-align: start;
  position: relative;
  background: #CECECE;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border: none !important;
  border-radius: 10px;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4D4D4D;
  height: 40px !important;
}

.mach-select {
  min-width: 167px !important;
  display: flex;
  align-items: center !important;
  position: relative;
  background: #CECECE;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border: none !important;
  border-radius: 10px;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4D4D4D;
}

.machine-drop-down .css-13cymwt-control{
  background-color: #CECECE !important;
  border-radius: 10px !important;
}


.reset-btns{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    max-width: 75px !important;
    min-width: 75px !important;
    height: 40px;
    background-color: #fcaf17 !important;
    color: white !important;
    text-align: center !important;
    border: 1px solid #fcaf17 !important;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
    margin-bottom: -15px !important;
    min-height: 40px !important;
    max-height: 40px !important;
}

.reset-btns:hover{
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  max-width: 75px !important;
  min-width: 75px !important;
  height: 40px;
  background-color: #fcaf17 !important;
  color: white !important;
  text-align: center !important;
  border: 1px solid #fcaf17 !important;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin: auto;
  text-transform: none !important;
  box-shadow: none !important;
  margin-bottom: -15px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}


.reset-btns-anom:hover{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    max-width: 75px !important;
    min-width: 75px !important;
    background-color: #fcaf17 !important;
    color: white !important;
    text-align: center !important;
    border: 1px solid #fcaf17 !important;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
    min-height: 38px !important;
    max-height: 38px !important;
}
.reset-btns-anom{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    max-width: 75px !important;
    min-width: 75px !important;
    background-color: #fcaf17 !important;
    color: white !important;
    text-align: center !important;
    border: 1px solid #fcaf17 !important;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
    min-height: 38px !important;
    max-height: 38px !important;
}

.mar-left-40-px{
  margin-left: 40px !important;
}


.machines__documents2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 25px 25px;
  margin-top: 65px !important;
}

.machines__documents2 .document_wrapper {
  width: 365px;
  font-weight: 500;
  padding: 0;
  /* margin-bottom: -18px !important; */
  border-radius: 6px;
    display: flex;
    justify-content: center;


}

.machines__documents2 .document-text {
  font-size: 20px;
  color: #4D4D4D;
}

.machines__documents2 .document-img_wrapper {
  width: 70px !important;
}

.machines__documents {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 48px 50px;
  margin-top: 65px !important;
}

.machines__documents .document_wrapper {
  width: 365px;
  font-weight: 500;
  padding: 0;
  /* margin-bottom: -18px !important; */
  border-radius: 6px;
    display: flex;
    justify-content: center;


}

.machines__documents .document-text {
  font-size: 20px;
  color: #4D4D4D;
}

.machines__documents .document-img_wrapper {
  width: 70px !important;
}

.document_wrapper {
  display: flex;
  width: 260px;
  padding: .6rem;
  border-radius: 6px;
  /* margin-bottom: -18px !important; */

}

.document_wrapper:hover {
  cursor: pointer;
  
}

.document-info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  margin-left: 32px !important;
}
.machine-info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  margin-left: 10px !important;
}
.document-text {
  font-size: 12px;
  color: var(--black-color) !important;
}

.document-img_wrapper {
  width: 70px !important;
}

.document-img_wrapper-2 {
  width: 130px !important;
    margin-top: 2% !important;
}
.machine-modal__img-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 30px 0;
}

.machine-modal__img:not(:last-child) {
  margin-right: 30px;
  box-shadow: (2px 2px 4px rgba(0, 0, 0, 0.25));
}

/* .pagination {
  display: inline-flex !important;
  align-items: center;
  max-width: 560px;
  padding: 6px 30px;
  background: #E0E0E0;
  border-radius: 10px;
  margin-bottom: 30px !important;
  height: 37px !important;
} */

.pagination__btn {
  background: #fff;
  border: 1px solid #ACACAC;
  border-radius: 10px;
  padding: 4px 10px;
  transition: all .3s;
  font-size: 12px !important; 
  width: 60px !important;
  height: 26px !important;
}

.pagination__btn:hover {
  background: #ACACAC;
  color: #fff;
  cursor: pointer;
}

.prev {
  margin-right: 20px;
}

.next {
  margin-left: 20px;
}

/* .pagination__page-number {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #ACACAC;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  transition: all 0.3s;
} */

.pagination__page-number:hover {
  cursor: pointer;
}

.pagination__page-number:not(:last-child) {
  margin-right: 10px;
}

/* .pagination__page-number.active {
  font-size: 16px;
  color: #fff;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
  background: radial-gradient(50% 50% at 50% 50%, #F79F00 0%, #FFAD16 100%);
  border-color: #C8860D;
} */

.txt-cntr{
  text-align: center !important;

}

.dsply-flex{
  display: flex !important;
}

.fnt-20px{
  font-size: 20px !important;
  color: #4D4D4D !important;
}

.view-documentation__documents {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-top: 43px;
  color: #4D4D4D !important;
}
.document-img_wrapper{
width: 70px !important;
}

.document-calender{
border-radius: 10px !important;
background-color:#CECECE !important;
}

.calender-input{
  border: none !important;
  background-color:#CECECE !important ;
  width: 135px !important;
  margin-top: 6px !important;
}

.font-15px{
  font-size: 15px !important;
}

.width-80px{
  width: 80px !important;

}

.text-right{
  text-align: right !important;
}
.text-left{
  text-align: left !important;
}

.popup-head{
 color: #4D4D4D !important;
 font-size: 12px !important;
}
.mr-top-4{
  margin-top: -4px !important;
}
.mr-b-10px{
margin-bottom: 10px !important;
}

.document {
  display: block;
  width: 380px;
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.15);
}

.modal_data {
  max-width: 670px;
  margin: 0 auto;
}
.tel-data_img {
  width: 100%;
}
.wdth-54 {
  width: 54% !important
  ;
}
@media screen and ( max-width:1340px){
  .wdth-54 {
  width: 100% !important
  ;
}
}
@media screen and ( max-width:767px){
  .wdth-54 {
  width: 54% !important
  ;
}
}
@media screen and ( max-width:1140px){
  .temperature{
    font-size: 50px !important;
    margin-top: 7px !important;
    color: #4d4d4d !important;
    font-style: normal !important;
  }

}

.tem-image-width{
  width: -webkit-fill-available !important;
}
.tem-image-width2{
  width: -moz-available !important;
}

.close-btn2{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 3px !important;
    /* padding-bottom: 6px !important; */
    min-width: 70px !important;
    max-width: 70px,!important;
    height: 30px;
    background-color: #fcaf17 !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    text-align: center !important;
    font-size: 13px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;

}
.next-btn2{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 3px !important;
    /* padding-bottom: 6px !important; */
    min-width: 70px !important;
    max-width: 70px,!important;
    height: 30px;
    background-color: #fcaf17 !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    text-align: center !important;
    font-size: 13px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;

}
.close-btn2:hover{
  padding-left: 16px !important;
  text-align: center !important;
    padding-right: 16px !important;
    padding-top: 3px !important;
    /* padding-bottom: 6px !important; */
    min-width: 70px !important;
    max-width: 70px,!important;
    height: 30px;
    background-color: #fcaf17 !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;

}

.close-btn3{
  display: grid !important;
  width: 70px !important;
}

.audit-text{
  font-size: 13px !important;
  color: black;
  margin-bottom: -15px !important;
}
.mr-l-20{
  margin-left: -20px !important;
}
.mr-l-85{
  margin-left: -85px !important;
}

.mr-l-115{
  margin-left: -115px !important;
}

.mr-r-15{
  margin-right: -15px !important;
}

.mr-l-74{
  margin-left: -74px !important;
}

.mr-r-32{
  margin-right: -32px !important;
}

.mr-r-43{
  margin-right: -43px !important;
}

.mr-l-26{
  margin-left: -26px !important;
}
.document-calender{
  border: none !important;
}
.css-13cymwt-control{
  border: none !important;

}
.css-13cymwt-control:hover{
  border: none !important;

}

.mr-lrft-150{
  margin-left: 150% !important;

}

.mr-top80px{
  margin-top: 80px !important;
}

.machine-icon{
  /* width:90px !important;
  height: 110px !important; */
margin-top: 4% !important;

}

.weather-icon{
  width:90px !important;
  height: 70px !important;

}
.celsius-icon{
  margin-left: -6px !important;
  margin-right: -20px !important;
  color: #4d4d4d !important;


}

.width-180{
  width: 180px !important;
}

.mr-b-5px{
  margin-bottom: -5px !important;
}
.mr-b-20{
margin-bottom: 20px !important;
}

.mr-b-30{
  margin-bottom: 30px !important;
  }
.mr-b-50{
  margin-bottom: 50px !important;
  }
.mr-t-5{
  margin-top: 5px !important;
}

.done-btn{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 3px !important;
    /* padding-bottom: 6px !important; */
    min-width: 70px !important;
    max-width: 70px,!important;
    height: 30px;
    background-color: #26672d !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    text-align: center !important;
    font-size: 13px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;

}
.done-btn:hover{
  
  padding-left: 16px !important;
  text-align: center !important;

    padding-right: 16px !important;
    padding-top: 3px !important;
    /* padding-bottom: 6px !important; */
    min-width: 70px !important;
    max-width: 70px,!important;
    height: 30px;
    background-color: #26672d !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;

}

.warn-btn{
  
  padding-left: 16px !important;
  text-align: center !important;
    padding-right: 16px !important;
    padding-top: 3px !important;
    /* padding-bottom: 6px !important; */
    min-width: 70px !important;
    max-width: 70px,!important;
    height: 30px;
    background-color: red !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;

}
.next-btn{
  
  padding-left: 16px !important;
  text-align: center !important;
    padding-right: 16px !important;
    padding-top: 3px !important;
    /* padding-bottom: 6px !important; */
    min-width: 70px !important;
    max-width: 70px,!important;
    height: 30px;
    background-color: red !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;

}
.machine-model{
  margin: 30px ;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  max-width: 690px;
  color: #4D4D4D;
}
.mr-t-10{
  margin-top: -10px !important;

}
.gap-15px{
  gap: 15px !important;
}
.flt-right{
  float: right !important;
}

.anomalies_wrapper {
  /* color: #4d4d4d; */
  font-size: 16px !important;
  margin-left: 12px !important;
}

.close-bt4{
  display: grid !important;
  width: 70px !important;
}

.close-bt5{
  display: grid !important;
  width: 80px !important;
}


.dsply-none{
  display: none !important;
}

.cla-align{
  width: 160px !important;
  margin-left: 14px !important;
}
.rst-btn{
  float: left !important;
}

.css-1xc3v61-indicatorContainer{
  color: #000000 !important;
}

.css-1dimb5e-singleValue{
  /* text-align: center !important; */
}
.mr-b-10{
margin-bottom: -10px !important;
}
.anom-documemt{
  width: 180px !important;
  margin-left: -14px !important;
}



.css-tj5bde-Svg path {
  d: path('M7.49988 12L-0.00012207 4L14.9999 4L7.49988 12Z') !important;
  color: #4d4d4d !important;
}
/* .css-tj5bde-Svg.active{
  d: path("M7.5 3L15 11H0L7.5 3Z") !important ;
  color: #4d4d4d !important;
} */


.view-documentation__selects {
  display: flex;
  align-items: baseline;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 25px;
}

.list-sheet-filters {
  align-items: baseline;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 60px !important;
  
}


.custom-select-container::after {
content: "";
position: absolute;
top: 50%;
right: 9px;
border: 7px solid transparent;
}

.custom-select-container.active::after {
border-color: transparent transparent #4C4C4C transparent;
position: absolute;
top: 50%;
right: 9px;
}

.custom-select-container_calendar::after {
content: '';
position: absolute;
top: 50%;
right: -14px;
border: none !important;
background: url('../assets/icons/calendar.svg') no-repeat center/contain;
width: 15px;
height: 15px;
color: #4d4d4d !important;
transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-moz-transform: translateY(-50%);
-ms-transform: translateY(-50%);
-o-transform: translateY(-50%);
}


.width83{
  width: 83% !important;
}
.width67{
  width: 67% !important;
}

.anomalies__selects {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px !important;
}

.anom-items{
  background: #CECECE !important;
  border: none !important;
  border-radius: 10px !important;
}
.css-13cymwt-control{
  background: white !important;
  border-radius: 10px !important;
  height: 22px !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
  
}
.anomalies__items {

  margin-top: 46px !important;
}

.anomalies__title {
  gap: 22px;
  color: #4d4d4d !important;
  font-size: 20px;
  margin-bottom: 20px !important;
  margin-top: 35px !important;
}

.mr-b-15px {
  margin-bottom: 15px !important;
  
}
.mr-b-8px {
  margin-bottom:8px !important;
  
}


.custom-select-container_anom::after {
content: "";
position: absolute;
top: 50%;
right: 9px;
border: 7px solid transparent;
}

.custom-select-container_anom.active::after {
border-color: transparent transparent #4C4C4C transparent;
position: absolute;
top: 50%;
right: 9px;
}



.selected-text {
  align-items: center;
  color: #4d4d4d;
  display: flex;
  height: 42px;
  justify-content: center;
  padding: 0.5rem 2rem !important;
  position: relative;
  text-transform: uppercase;
  width: 100%;
  z-index: 1;
}

.custom-select-container::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 9px;
  border: 7px solid transparent;
  border-color: #4C4C4C transparent transparent transparent;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  -moz-transform: translateY(-25%);
  -ms-transform: translateY(-25%);
  -o-transform: translateY(-25%);
  transition: all .3s;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
}

.custom-select-container.active::after {
  border-color: transparent transparent #4C4C4C transparent;
  position: absolute;
  top: 50%;
  right: 9px;
  transform: translateY(-75%);
  -webkit-transform: translateY(-75%);
  -moz-transform: translateY(-75%);
  -ms-transform: translateY(-75%);
  -o-transform: translateY(-75%);
  transition: all .3s;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
}



.select-options,
.calendar {
  position: absolute;
  min-width: 285px;
  width: 100%;
  top: calc(100% + 7px);
  left: 0;
  padding: 3px;
  background-color: #eeeeee;
  border-radius: 6px;
  text-align: center;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  z-index: 2;
}

.select-options {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.calendar {
  left: auto;
  right: 0;
}

.select-filter__wrapper {
  padding: 7px;
}

.select-filter {
  width: 100%;
  padding: 10px;
  outline: none;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.custom-select-option {
  align-items: center;
  gap: 7px;
  text-align: left;
  list-style: disc;
  padding: 12px 16px;
  background-color: #ffffff;
  color: #212529;
  font-size: 12px;
  cursor: pointer;
}

.custom-select-option::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

/* .custom-select-option:nth-child(2):before {
  background-color: #198754;
}

.custom-select-option:nth-child(3):before {
  background-color: #0D6EFD;
}

.custom-select-option:nth-child(4):before {
  background-color: #DC3545;
}

.custom-select-option:nth-child(5):before {
  background-color: #0DCAF0;
}
.custom-select-option:nth-child(6):before {
  background-color: #0f6273;
}
.custom-select-option:nth-child(7):before {
  background-color: #b5bcbd;
}
.custom-select-option:nth-child(8):before {
  background-color: #15096c;
}
.custom-select-option:nth-child(9):before {
  background-color: #1cf01c;
}
.custom-select-option:nth-child(10):before {
  background-color: #7bc125;
}
.custom-select-option:nth-child(11):before {
  background-color: #18aecc;
}
.custom-select-option:nth-child(12):before {
  background-color: #bf1779;
}
.custom-select-option:nth-child(13):before {
  background-color: #892435;
} */
.custom-select-option:before {
  background-color: #fcaf17;
}

.custom-select-option:hover {
  background-color: var(--grey-color);
  color: var(--white-color);
}

/* ----------Calendar----------- */

.react-calendar {
  width: 400px;
  max-width: 100%;
  background-color: white;
  border-color: transparent transparent #4C4C4C transparent;
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: 'Roboto' !important;

  line-height: 1.125em;
}

.react-calendar__navigation {
  height: 20px;
}

.react-calendar__tile--hasActive {
  background-color: #fcaf17;
  color: white;
}

.react-calendar__tile {
  color: #4d4d4d;
}

.react-calendar__year-view .react-calendar__tile {
  padding: .5rem 0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #fcaf17 !important;
  color: #fff !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}


.custom-select-container, .custom-select-container_calendar {
  background: #cecece;
  border-radius: 10px;
  border: none !important;
  /* box-shadow: 0 2px 2px rgb(0 0 0 / 25%); */
  cursor: pointer;
  min-height: 41px;
  min-width: 170px;
  position: relative;
  text-align: center !important;
  color: #4d4d4d !important;
}

.react-calendar__tile:enabled:focus, .react-calendar__tile:enabled:hover {
  background-color:#fcaf17 !important;
  color: #fff !important;
}
.react-calendar__tile--now{
  background-color:#fcaf17 !important;
  color: #fff !important;
}

.date-range .rmdp-day-picker .rmdp-week, .rmdp-ym{
  display: flex !important;
}

.date-range .rmdp-day-picker {
  display: flex !important
}

.date-range .rmdp-input{
  border: none !important;
  border-radius: 10px !important;
  height: 41px !important;
  background-color: #cecece !important;
  color: #4d4d4d !important;
  text-align: center !important
}

.telematics-head{
  /* font-family: "Times New Roman", Times, serif !important; */
  font-family: 'Roboto' !important;

  color: rgb(87, 83, 83);
  padding-top: 20px;
  padding-bottom: 30px;
}

.password-head{
  padding-top: 25px !important;
  padding-bottom: 0px !important;
  font-size: 32px !important;
  color: gray;
  font-weight: 500;
}


.telematics_wrapper {
  display: flex;
  width: 260px;
  padding: .6rem;
  border-radius: 6px;
  margin-bottom: 25px !important;
}

.gap-0{
  gap: 0% !important;
}


.password-msg{
  width: 100% !important;
    text-align: center !important;
}

.password-btn-disabled{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 150px;
    max-width: 150px;
    height: 36px;
    background-color: #f1f1f1 !important;
    color: black !important;
    border: 1px solid #f1f1f1 !important;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
}

.password-btn{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 150px;
    max-width: 150px;  
    height: 36px;
    background-color:#fdba29cf !important;
    color: black !important;
    border: 1px solid #fdba29cf !important;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
}

.wdth{
  width: -moz-fit-content !important;
  width: -webkit-fill-available !important;
}

.pd-tp-bt-20{
  padding-top: 20!important;
  padding-bottom: 20 !important;

}

.m-l-25px{
  margin-left: 25px !important;
}
.m-l-30px{
  margin-left: 30px !important;
}
.mr-t-10{
margin-top: -10px !important;
}
.m-l-16px{
  margin-left: 16px !important;
}
.message-area{
  background-color: #f1f1f1 !important;
  border-color: #f1f1f1 !important;
  border: 0px !important;
  border-radius: 5px !important;
  padding: 5px 35px 5px 5px !important;
  box-sizing: border-box !important;
  width: 100% !important;
  float: right !important;
}

.audit-and-approve-btn{
padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 191px !important;
    max-width: 191px !important;
    height: 36px;
    background-color: #fcaf17 !important;
    color: white !important;
    border: 1px solid #fcaf17 !important;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;}

.audit-and-approve-btn:hover{
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  min-width: 191px !important;
  max-width: 191px !important;
  height: 36px;
  background-color: #fcaf17 !important;
  color: white !important;
  border: 1px solid #fcaf17 !important;
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin: auto;
  text-transform: none !important;
  box-shadow: none !important;
}

.updt-btn{
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  min-width: 100px;
  max-width: 100px;
  height: 36px;
  background-color: #fcaf17 !important;
  color: white !important;
  border: 1px solid #fcaf17 !important;
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin: auto;
  text-transform: none !important;
  box-shadow: none !important;
}

.p-50{
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.modal-fullscreen{
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.wdth-80{
  width: 80% !important;
}
.wdth-85{
  width: 85% !important;
}

.bg-clr{
  background-color: #efeded !important
}

.msg-box{
  border-radius: 20px !important;
  border-color: white !important;
  background-color: white !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  /* margin-left: 22px !important; */
  /* margin-right: 30px !important; */
  width: 88% !important;
}






.msg-txt{
  margin-left: 16px !important;
  margin-right: 16px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: #4d4d4d !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-family: 'Roboto' !important;

}

.gMliOo svg {
  display:  none !important;
}




.msg-save-btn{
  padding-left: 16px !important;
      padding-right: 16px !important;
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      min-width: 80px !important;
      max-width: 80px !important;
      height: 36px;
      background-color: #fcaf17 !important;
      color: white !important;
      border: 1px solid #fcaf17 !important;
      box-sizing: border-box;
      border-radius: 3px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      display: block;
      margin: auto;
      text-transform: none !important;
      box-shadow: none !important;
      margin-top: 10px !important;
      margin-right: 12px !important;
      float: right !important;
    }
  
  .msg-save-btn:hover{
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 80px !important;
    max-width: 80px !important;
    height: 36px;
    background-color: #fcaf17 !important;
    color: white !important;
    border: 1px solid #fcaf17 !important;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
    margin-top: 10px !important;
    margin-right: 12px !important;
    float: right !important;
  }


  .msg-notification{
    background-color: #ffcc00;
    border: none !important;
    color: #4D4D4D !important;
    font-size: 24px !important;
    min-height: 60px !important;
    padding-top: 15px !important;
    border-radius: 5px !important;
  }
  
  .wdth-85{
    width: 85 !important;
  }

/* li{
  margin-left: 12px !important;
  } */

.fcZkth{
min-width: 18% !important;
max-width: 18% !important;
padding-top: 7px !important;
  }
  .jTCDaL{
padding-top: -8px !important;

  }
  .hecCuC{
padding-top: -4px !important;

  }

  .jKAZdd{
padding-top: -5px !important;

  }

  .aGncM svg {
    display: none !important;

  }

  



  .hbotQl{
    overflow: hidden !important;
  }


  .pd-tp-20px{
    padding-top: 23px !important;
  }

  .wthr-box{
    font-size: 24px;
    color: #4d4d4d !important;
    padding-left: 24px !important;
    /* margin-top: 10px !important; */

  }

  .temperature{
    font-size: 58px !important;
    margin-top: 7px !important;
    color: #4d4d4d !important;
    font-style: normal !important;
}
@media screen and ( max-width:1440px){
  .temperature{
    font-size: 50px !important;
    margin-top: 7px !important;
    color: #4d4d4d !important;
    font-style: normal !important;
  }

}
@media screen and ( max-width:1482px){
  
  .wthr-box{
    font-size: 21px !important;
    color: #4d4d4d !important;
    /* margin-top: 10px !important; */

  }
}
@media screen and ( max-width:1402px){
  
  .wthr-box{
    font-size: 19px !important;
    color: #4d4d4d !important;
    /* margin-top: 10px !important; */

  }
}

  .gry-clr{
    color: #4d4d4d !important;

  }

  .rw-forecast-days-panel{
    display: none !important;
  }
  .graph-head{
    margin-left: 5% !important;
    padding-top: 1% !important;
    color: #999896 !important;

  }

  .pr-0{
    padding-right: 0px !important;
  }
  .pl-0{
    padding-left: 0px !important;
  }
  
  .dshbd-head{
    margin-top: 8px !important;
    font-size: 20px !important;
    text-align: left !important
  }

  .pd-top10{
    padding-top: 1px !important;
  }

  .clr-green{
    color: green !important
  }

  .clr-red{
    color: red !important
  }

.clr-yellow{
    color: #fcaf17 !important
  }
  .clr-black{
    color: black !important
  }

  .sheet-count{
    font-size: 80px !important;
    font-weight: bold !important;
    margin-top: 40px !important;
    color: #4d4d4d !important;
    font-family: 'Roboto' !important;

  }
  /* .sheet-count:hover{
    font-size: 90px !important;
  } */

  .icon-1:hover{
    font-size: 85px !important;

  }
.fnt-35{
  font-size: 35px !important;
}

.mrg-top-btm{
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  border: 2px !important;
  border: dashed !important;
  border-color: gray !important;
}


.save-mach-btn{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 180px !important;
    max-width: 180px !important;
    height: 36px;
    background-color: #fdba29cf !important;
    color: black !important;

    border: 1px solid #ffb614cf !important;
    box-sizing: border-box;
    border-radius: 3px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
}


.save-mach-btn:hover{
  padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-width: 180px !important;
    max-width: 180px !important;
    height: 36px;
    background-color: #fdba29cf !important;
    color: black !important;

    border: 1px solid #ffb614cf !important;
    box-sizing: border-box;
    border-radius: 3px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    display: block;
    margin: auto;
    text-transform: none !important;
    box-shadow: none !important;
}
.wdth-96{
  width: 96%!important;
}

.wdth-95{
  width: 95%!important;
}

.edit-btn2:hover{
  padding-left: 16px !important;
  text-align: center !important;
    padding-right: 16px !important;
    padding-top: 3px !important;
    /* padding-bottom: 6px !important; */
    min-width: 70px !important;
    max-width: 70px,!important;
    height: 30px;
    background-color: green !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;

}
.edit-btn2{
  padding-left: 16px !important;
  text-align: center !important;
    padding-right: 16px !important;
    padding-top: 3px !important;
    /* padding-bottom: 6px !important; */
    min-width: 70px !important;
    max-width: 70px,!important;
    height: 30px;
    background-color:green !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;
}

.delete-btn2{
  padding-left: 16px !important;
  text-align: center !important;
    padding-right: 16px !important;
    padding-top: 3px !important;
    /* padding-bottom: 6px !important; */
    min-width: 70px !important;
    max-width: 70px,!important;
    height: 30px;
    background-color: red !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;
}
.delete-btn2:hover{
  padding-left: 16px !important;
  text-align: center !important;
    padding-right: 16px !important;
    padding-top: 3px !important;
    /* padding-bottom: 6px !important; */
    min-width: 70px !important;
    max-width: 70px,!important;
    height: 30px;
    background-color: red !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    display: block;
    text-transform: none !important;
    box-shadow: none !important;
    border-radius:5px;
}

.m-t-l{
  margin-left: 15px !important;
  padding-right: 0% !important;
  padding-top: 0% !important;
}
@media screen and (max-width:766px){
  .m-t-l{
  margin-left: -1.7% !important;

    padding-right: 0% !important;
    padding-top: 0% !important;
  }


}

.border-box5 {
  box-shadow: 1px 2px 9px #c7ccb2;
  border-radius: 10px !important;
  }

.mr-btm-40{
  margin-bottom: 40px !important
}
.pd-0{
  padding: 0px !important;
}

.msg-bx{
  padding-right: 10px;
    padding-bottom: 1px;
}
.msg-box2{
    border-radius: 20px !important;
    border-color: white !important;
    background-color: white !important;
    margin-top: 45px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    margin-bottom: 35px !important;
    margin-left: 22px !important;
    margin-right: 30px !important;
    width: 85% !important;
}


@media screen and (max-width:766px){
  .msg-box2{
    border-radius: 20px !important;
    border-color: white !important;
    background-color: white !important;
    margin-top: 45px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    margin-bottom: 35px !important;
    margin-left: 19% !important;
    margin-right: 30px !important;
    width: 60% !important;
}

  }
  .margin-left-20{
    margin-left: 0px ;
  }
  @media screen and (max-width: 770px){}
  .margin-left-20 {
      margin-left: 0px !important;
  }
  @media screen and (max-width:1335px){
    .margin-left-20{
      margin-left: 20px !important;
    }
    /* .wdth-54 {
      width: 54% !important;
  } */
    }


  @media screen and (max-width:766px){

  .msg-box{
    border-radius: 20px !important;
    border-color: white !important;
    background-color: white !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    /* margin-left: 22px !important; */
    /* margin-right: 30px !important; */
    width: 70% !important;
  }
}

.line{
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 42px;

color: #C5C5C5;
}

.wind-txt{
  font-weight: 500 !important;
font-size: 14px !important;
line-height: 16px !important;
color: #4D4D4D !important;

}

.bar-icon{
  margin-left: 28px !important;
  margin-top: 5px !important;
  font-size: 16px !important;
  color: #4d4d4d !important;
}

.wdth-90{
  width: 90% !important
}
.wdth-94{
  width: 100% !important
}
.mrr-5px{
  margin-right: 5px !important;
}

.brdr-dash{
  border-top: 4px dashed #7f7a7a;
}
.mb35 {
  margin-bottom: 35px !important;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.edit-icon{
  font-size: 20px !important;
    font-weight: normal !important;
    color: gray !important;
}

.edit-icon:hover{
  font-size: 20px !important;
    font-weight: normal !important;
    color: black !important;
    cursor: pointer !important
}

.mr-l-18{
  margin-left: 20% !important;
}

.min-wdth-220{
min-width: 240px !important;}

.rl-110{
margin-left: 110px !important}


.down-icon{
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-left: 5px !important;
}
.crsr-pointer-menu2{
cursor: pointer !important;
margin-top: 10px;
color: gray;
background-color: white;
border: none !important;
text-align: left !important;
padding-left: 10px !important;
padding-right: 10px !important;


}

.crsr-pointer-menu3{
  text-decoration: unset !important;
  border: none !important;
  background-color: white;
}


.nav-wdth{
  width: 55% !important
}

@media screen and (max-width:960px){
  .nav-wdth{
    width: 90% !important
  }


}
.menu-popup-top2{
  top: 0px !important;
  cursor: pointer;
}


.cntnt{
  top: 0px !important;
}


.popup-content.menu-popup-top-content{
  top: 94px !important;
}


.txt-algn-lft{
  text-align: left !important;
}

.btn-close{
  border: none !important;
}
.btn-close:hover{
  border: none !important;
}
  .aGncM {
        width: 80px !important;
        margin-left: -5px !important;
        margin-top: 9px !important;
  }


NavLink{
    border: none !important;
    text-decoration: none !important;

  }

a:focus-visible {
  outline: none !important;
}
button:focus-visible{
  outline: none !important;
  box-shadow: none !important;
}

button:focus{
  outline: none !important;
  box-shadow: none !important;
}
.sgn-img{
  width: 79% !important;
    float: right !important;
    background: #f1f1f1 !important;
    padding-left: 23px;
    margin-right: 16px !important;
    border-radius: 5px;
    min-height: 122px !important;
}

.select-options-height {
  max-height: 400px !important;
  overflow: auto !important;
}
.select-options-height2 {
  max-height: 400px !important;
  overflow: auto !important;
}

.mrt-6{
margin-top: 6% !important
}

.img-tag {
    background-image: url("../assets/images/logo.png");
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}

.hght-850px{
  min-height: 650px !important;
}
.hght-29{
  min-height: 300px !important;
  max-height: 450px !important;
}
.scrl{
  overflow: scroll !important;
}
.temp-bars{
  margin-top: 15px !important;
  text-align: right !important;
  padding-right: 18px !important;
}

.anm-status-btn-green{
  min-width: 50px;
  max-width: 50px !important;
  height: 23px !important;
  background-color: #54c50f !important;
  color: black !important;
  border: 1px solid #54c50f !important;
  box-sizing: border-box;
  border-radius: 5px;
}
.anm-status-btn-red{
  min-width: 50px;
  max-width: 50px !important;
  height: 23px !important;
  background-color: #ba2f2c !important;
  color: black !important;
  border: 1px solid #ba2f2c !important;
  box-sizing: border-box;
  border-radius: 5px;
}

.anm-status-btn-yellow{
  min-width: 50px;
  max-width: 50px !important;
  height: 23px !important;
  background-color: #feb611 !important;
  color: black !important;
  border: 1px solid #feb611 !important;
  box-sizing: border-box;
  border-radius: 5px;
}

.anm-status-btn-gray{
  min-width: 50px;
  max-width: 50px !important;
  height: 23px !important;
  background-color: gray !important;
  color: black !important;
  border: 1px solid gray !important;
  box-sizing: border-box;
  border-radius: 5px;
}
/* .mr-bt-180{
margin-bottom: -180px !important;
}

@media screen and ( max-width:767px){
  .mr-bt-180{
    margin-bottom: 0px !important;
    }
} */

/* 1100px */

.anom-img{
  width:60px !important;
  height:25px !important
}

.po-img{
  width: 60px !important;
}

/* ----new----- */
/* .daterangepicker select.monthselect, .daterangepicker select.yearselect{
  font-size: 16px;
  padding-left: 10px;
  height: 40px ;
  border-radius: 6px !important;

  margin: 0;
  cursor: default;
  background-color: aliceblue !important;
}
.daterangepicker select.monthselect{
  margin-right: 5px !important;
  width: 70% !important;
}

.daterangepicker select.yearselect{
  width: 47% !important;
}
.calendar-table{
  box-shadow: 1px 2px 9px lightgray !important;
  border-radius: 6px !important;
}

.table-condensed .prev {
  display: none;
}


.table-condensed .next {
  display: none;
}

.daterangepicker .drp-selected{
    display: inline-block !important;
    font-size: 15px !important;
    padding-right: 8px !important;
    float: left !important;
    padding-top: 10px !important;
}


.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top:none !important;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  padding: 4px 8px !important;
  min-width: 90px !important;
  max-width: 90px !important;
  height: 35px !important;
  box-shadow: 1px 2px 5px lightgray !important;
} */

.daterangepicker.opensright:before {
  display: none !important;
}
.daterangepicker:after {
  top: 0px !important;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

/* new css started here */

.ring-1 {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.bg-white {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.text-white {
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.py-10 {
  padding-top: 4rem !important;
  padding-bottom: 2.5rem !important;
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

@media screen and (min-width: 1024px){
  .lg\:px-8 {
      padding-left: 2rem;
      padding-right: 2rem;
  }

}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1.7rem !important;
}

.pagination {
  align-items: center !important;
  max-width: 560px !important;
  margin-bottom: 30px !important;
  height: 37px !important;
  padding-top: 29px !important;
  background-color: #F3F4F6 !important;
}
.pagination__page-number {
display: flex;
align-items: center;
justify-content: center;
background: #F3F4F6 ;
border:none !important;
width: 24px;
height: 24px;
font-size: 15px;
font-weight: 500 !important;
transition: all 0.3s;
}
.pagination__page-number.active {
font-size: 15px !important;
color: white !important;
font-weight: 500 !important;
border: none !important;
width: 40px !important;
height: 37px !important;
text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
background: radial-gradient(50% 56% at 50% 50%, #fcae18 0%, #FFAD16 100%);
border-radius: 50px !important;
}




.pagination2 {
  align-items: center !important;
  max-width: 560px !important;
  margin-bottom: 30px !important;
  height: 37px !important;
  padding-top: 29px !important;
  background-color: white !important;
  display: flex;
}
.pagination2__page-number {
display: flex;
align-items: center;
justify-content: center;
background: #fff;
border:none !important;
width: 24px;
height: 24px;
font-size: 18px;
font-weight: 500 !important;
transition: all 0.3s;
}
.pagination2__page-number.active {
font-size: 19px !important;
color: white !important;
font-weight: 500 !important;
border: none !important;
width: 40px !important;
height: 35px !important;
text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
background: radial-gradient(50% 56% at 50% 50%, #F79F00 0%, #FFAD16 100%);
border-radius: 45px !important;
}

.pagination2__btn {
  background: #fff;
  border: 1px solid #ACACAC;
  border-radius: 10px;
  padding: 4px 10px;
  transition: all .3s;
  font-size: 12px !important; 
  width: 60px !important;
  height: 26px !important;
}

.pagination2__btn:hover {
  background: #ACACAC;
  color: #fff;
  cursor: pointer;
}
.pagination2__page-number:hover {
  cursor: pointer;
}

.pagination2__page-number:not(:last-child) {
  margin-right: 6px;
}

.w-48 {
  width: 12rem;
}

.h-36 {
  height: 9rem;
}

.fs-1.5 {
  font-size : 1.5 rem !important
}

hr {
  border-top-width: 3px !important;
}

.date-range {
  width: 20rem;
}