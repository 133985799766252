.App {
  display: flex !important;
}
:root {
  --primary-color: #fcaf17;
  --text-color: #030b16;
  --text-grey-color: #657488;
}

.side-bar {
  /* padding-block: 20px; */
  min-height: 100svh;
  height: auto !important;
  /* padding: 20px 0 0 !important; */
}
.ps-sidebar-container {
  display: flex;
  flex-direction: column;
}
.ps-menu-root {
  flex: 1;
}
.side-bar__logo {
  /* max-width: 80px;
  width: 100%; */
  margin-inline: auto;
  margin-bottom: 4px;
  margin-top: 10px;
  transition: all 0.3s;
}

/* .side-bar__logo img {
  width: 110px;
} */
.side-bar__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  background-color: transparent;
  margin-inline: auto 20px;
  border-radius: 8px;
  transition: all 0.3s;
}
.side-bar__btn:hover {
  background: #f3f3f3;
}
.side-bar__btn:hover path {
  fill: var(--text-color);
}
.side-bar__menu-item:hover {
  color: #ffffff;
}
.side-bar__menu-item > a {
  height: 56px !important;
  color: var(--text-grey-color) !important;
}
.side-bar__menu-item > a:hover {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}
.side-bar__menu-item > a:hover path {
  fill: #ffffff;
  stroke: #ffffff;
}
.ps-menuitem-root {
  background-color: transparent;
  color: var(--text-grey-color) !important;
}
.ps-menuitem-root > a:hover {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}
.ps-menuitem-root:hover .side-bar__menu-item > a {
  background-color: transparent !important;
  color: #ffffff !important;
}
.ps-submenu-expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  transition: margin 0.3s;
}
.ps-submenu-expand-icon > span {
  width: 12px !important;
  height: 12px !important;
  transform: rotate(45deg) !important;
}
.ps-submenu-expand-icon.ps-open {
  margin-top: 8px;
}
.ps-submenu-expand-icon.ps-open > span {
  transform: rotate(225deg) !important;
}
.css-um1o6k {
  display: none;
}
.side-bar__user {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 16px;
  border-top: 1px solid #e5eaee;
  color: var(--text-grey-color);
}
.side-bar__user-img-wrapper {
  width: 54px;
}
.side-bar__user-img-wrapper img {
  width: 100%;
}
.side-bar__user-name {
  margin-inline: 14px 10px;
}
.side-bar__user-arrow {
  display: block;
  width: 7px;
  height: 7px;
  margin-bottom: 3px;
  border-bottom: 1px solid var(--text-grey-color);
  border-right: 1px solid var(--text-grey-color);
  transform: rotate(45deg);
}

.brdr-none{
  border: none !important;
}
.css-ewdv3l {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #696871 !important;
}
.ps-menu-button{
  padding-left: 20px !important;
  padding-right: 30px !important;
}
.css-1sdbk0q.ps-collapsed {
  min-width: 92px !important
}

.dropdowns {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-block: 20px 30px;
}

.dropdowns__btn {
  width: 120px;
  height: 40px;
  font-weight: 500;
  color: #ffffff;
  background: url('../assets/images/reset.svg') no-repeat center left 20% / 20px, var(--primary-color);
  border-radius: 8px;
  cursor: pointer;
  border: none !important;
}
.dropdowns__btn:hover {
  opacity: 0.8;
  border: none !important;

}

.fa-bars{
  
  color: #8d99a6 !important;
  padding-bottom: 15px !important;
}
.user-clr{
  color: #8d99a6 !important;

}
.dropdown-toggle{
  background-color: white !important;
  border: none !important;}


  .title-search {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
  }
  .title-search__title-wrapper {
    display: flex;
    flex-direction: column;
  }
  .title-search__title-wrapper h1 {
    font-size: 24px;
    line-height: 150%;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 5px;
  }
  .title-search__title-wrapper p {
    line-height: 140%;
    color: var(--text-grey-color);
  }
  .title-search label {
    /* position: relative; */
    max-width: 300px;
    width: 100%;
  }
  .title-search img {
    width: 20px;
    height: 20px;
    /* position: absolute; */
    margin-top: 2px !important;
    margin-left: 20px !important;
    margin-right: 8px !important;
    /* transform: translateY(-50%); */
  }
  .title-search label span {
    position: absolute;
    margin-top: 8px !important;
    margin-left: 4px !important;
    /* transform: translateY(-50%); */
    line-height: 140%;
    color: #aeb8c6;
    cursor: text;
  }
  .title-search input {
    width: 100%;
    height: 40px;
    color: var(--text-color);
    padding: 4px 8px;
    background: #ffffff;
    border: 1px solid #e6eaef;
    border-radius: 8px;
  }
  .title-search input:focus {
    outline: none;
    border-color: var(--primary-color);
  }
  .wdth-110{
    width: 110px !important;
  }

  .add-icon{
    margin-right: 10px !important;
    margin-left: 20px !important;
    width: 23px !important;
  }
  .view-icon{
    margin-right: 10px !important;
    margin-left: 20px !important;
    width: 23px !important;
  }

  .ps-menu-label{
    display: flex !important;
      gap: 10px !important;
  }

  .date-range{
    background: white !important ;
    border-radius: 5px !important;
    height: 35px !important;
    margin-top: 10px !important;
  }
  .custom-select-container_calendar::after {
    /* padding-bottom: 10px !important; */
    top:40% !important;
  }
  .custom-select-container_calendar {
    padding-bottom: 7px !important;
    font-size: 14px !important;
    color: #030B16 !important;
  }
  .applyBtn{
    background-color: #F79F00 !important;
    border: none !important;
  }

  .dropdown-item{
    display: flex !important;
  }
  .rmdp-shadow{
    box-shadow: none;
    border: 1px solid  #d1d5da ;
    /* --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #d1d5da ) !important; */

    
  }
  .rmdp-header{
      font-size: 14px !important;
      /* height: 38px !important; */
      line-height: 37px !important;
      margin-top: -3px !important;
      margin-bottom: -4px !important;
      width: 170px !important;
  }
  .min-width-350{
    min-width: 350px !important;

  }
  .go-back-btn{
    font-size: 15px !important;
    font-weight: 500 !important;
    border: none !important;
  }

  .required-field{
    color:  red !important;
  }

  .mx-hgt-400{
    height: 400px !important;
  }

.clr-sign{
  float: left !important;
    margin-top: 5px !important;
}
.view-signature{
  background-color: #f1f1f1 !important;
    height: 70px !important;
    border-radius: 5px !important;

}

.edit-signature{
  width: 32% !important;
  height: 120px !important;
}
.rmdp-month-picker{
  display: none !important;
}
.rmdp-year-picker{
  display: none !important;
}
.view-signature-size{
  background-color: #f1f1f1!important;
  padding: 2px;
  border-radius: 7px;
  min-height: 70px !important;
}
.update-pass-msg{
  color: red !important;
  margin-top: 15px !important;
}
.css-13cymwt-control {
  background-color:  white !important;
  min-height: 24px !important;
  margin-left: 2px !important;
}
.css-13cymwt-control:active{
  margin-left: 2px !important;
  background-color:  white !important;
  min-height: 24px !important;
  
}

.css-13cymwt-control:focus {
  background-color:  white !important;
  max-height: 24px !important;
  margin-right: 2px !important;
  margin-left: 2px !important;
}
.css-t3ipsp-control {
  border-color: white !important;
  box-shadow: none !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  background-color:  white !important;
  min-height: 24px !important;
  max-height: 24px !important;

}
.css-t3ipsp-control:hover{
  border: white !important;
  margin-right: 2px !important;
  margin-left: 2px !important;
  background-color:  white !important;
  min-height: 24px !important;
  max-height: 24px !important;
}

#purchase-order-number input:focus{
  box-shadow: none !important;
}
#SiteName input:focus{
  box-shadow: none !important;
}
#vehicleCode input:focus{
  box-shadow: none !important;
}
.css-1fdsijx-ValueContainer{
  /* margin-top: -10px !important; */
}
.css-1u9des2-indicatorSeparator{
display: none !important;
}
.css-tj5bde-Svg{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%236b7280%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e") !important;
  background-position: right 0.5rem center !important;
  background-repeat: no-repeat !important;
  background-size: 1.5em 1.5em !important;
  padding-right: 1.5rem !important;
  -webkit-print-color-adjust: exact !important;

}

.css-8mmkcg{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%236b7280%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e") !important;
  background-position: right 0.5rem center !important;
  background-repeat: no-repeat !important;
  background-size: 1.5em 1.5em !important;
  padding-right: 1.5rem !important;
  -webkit-print-color-adjust: exact !important;

}

.css-15lsz6c-indicatorContainer{
  padding-top: 2px !important;
  padding-right: 1px !important;
}

.css-1xc3v61-indicatorContainer{
  padding-top: 2px !important;
  padding-right: 1px !important;
}

.css-1dimb5e-singleValue{
  margin-top: -11px !important;
}

.css-1cfo1cf{
  margin-top: -11px !important;
}

.min-hgt-574{
  min-height: 574px !important;
}
.report-loader{
  text-align: center !important;
  margin-top: 26% !important;
}

.pdf-container {
  margin-top: 20px !important;
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  font-family: Arial, sans-serif;
  background-color: white !important;

}


.wdth-80{
  width: 80% !important;
}

.mar-left-5px{
  margin-left: 15px !important;
}

.mar-rght-5px{
  margin-right: 15px !important;
}


/* .zoom-mrgn{
  padding-top: 10px !important;
  margin-bottom: -5px !important;
} */
.bg-color-gray-black{
  background: #525659 !important;

}

.zoom-btns{
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  color: white !important;

}
.new-pad{background-color: #f1f1f1 !important;
  border-radius: 7px!important;
  height: 120px!important;
  width: 100%!important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  padding-left: 80px !important;
  padding-right: 80px !important;
}
.signatureCanvas2{
  width:100% !important;
  height:120px !important;
  background-color: #f1f1f1;
  border-radius: 7px !important;

  /* border-radius: 7px !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  padding-left: 5% !important;
  padding-right: 5% !important; */
}
.signatureCanvas{
  width:32% !important;
  height:120px !important;
  background-color: #f1f1f1;
  border-radius: 7px !important;
 
}
.signatureCanvasNew{
  width:100% !important;
  height:120px !important;
  background-color: #f1f1f1;
  border-radius: 7px !important;
 
}


.custom-file-upload {
  display: block;
  position: relative;
  background-color: #fcaf18;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  width: 100%;
  margin: auto;
  border-radius: 0.30em;
  cursor: pointer;
}

.file-btn{
  border: 0px solid !important;
  border-color: lightgray !important;
}

.brdr-radius{
  border-radius: 10px !important;
}

.h-95-percent{
height: 95% !important;
}
/* .microsoft-login-button {
  background-color: #2f2f2f;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  width: 100% !important;
  margin-top: 20px !important;
} */
.microsoft-login-button {
  background-color: #ffffff;
  color: #2f2f2f;
  border: solid 1px;
  border-radius: 6px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  width: 100% !important;
  margin-top: 20px !important;
}
.button-icon {
  width: 30px !important;
  height: 27px !important;
  margin-right: 10px !important;
  margin-left: 8% !important;
}

.button-text {
  margin-right: 5px;
}


.or-container {
  align-items: center;
  color: #ccc;
  display: flex;
  margin: 25px 0
  }
  .line-separator {
  background-color: #ccc;
  flex-grow: 5;
  height: 1px
  }
  .or-label {
  flex-grow: 1;
  margin: 0 0px;
  text-align: center
  }

  .upload-btn{
    width: 200px !important;
  }

  .wdth-173{
   width:  173px !important;
  }

  /* Css added */
  @media (min-width: 1024px) {
    .lg\:w-96 {
        width: 40rem !important;
    }
}

.max-w-sm {
    max-width: 38.5rem !important;
}

.leading-9 {
  line-height: 38px !important;
}

.text-2xl {
  font-size: 24px !important;
  line-height: 2rem !important;
}

.mt-4 {
  margin-top: 3rem !important;
}
.mt-12 {
  margin-top: 5.5rem !important;
}

.leading-6 {
  line-height: 24px !important;
}

.text-sm {
  font-size: 14px !important;
  line-height: 3rem !important;
}

@media (min-width: 640px) {
  .sm\:text-sm {
      font-size: 1.5rem !important;
      line-height: 3rem !important;
  }
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(2rem* calc(1 - var(--tw-space-y-reverse))) !important;
}
.mt-1 {
  margin-top: 0.8rem !important;
}
/* @media (min-width: 640px) {
  .sm\:p-6 {
      padding: 4rem !important;
  }
} */
.gap-5 {
  gap: 4.5rem !important;
}
.text-3xl {
  font-size: 30px !important;
}
.font-semibold {
  font-weight: 1000 !important;
}
.gap-8 {
  gap: 3rem !important;
}
.my-8 {
  margin-top: 3rem !important;
  margin-bottom: 2rem !important;
}
.max-w-xs {
  max-width: 32rem !important;
}
.fs-14 {
  font-size: 14px !important;
}
.min-w-\[10rem\] {
  min-width: 15rem !important;
}
.jcc {
  justify-content: center;
}
.gap-10 {
  gap: 10px !important;
}
.padd-10{
  padding: 8px 0 0 15px !important;
}
.text-xs {
  font-size: 12px !important;
  line-height: 1rem !important;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}
.height-40 {
  height: 37px !important;
}
.hw-2 {
  height: 2rem !important;
  width: 2rem !important;
}
label {
  font-weight: 100 !important;
}
.text-tn {
  font-size: 1.8rem;
  line-height: 2.5rem;
}
.pn {
  padding: 1.5rem;
}
.ps-menu-button {
  outline: none;
}

.ps-menu-button:focus {
  outline: none;
  box-shadow: none;
}

.mon-label {
  border-radius: 8px !important;
  padding: 10px 8px !important;
  text-decoration: none !important;
  outline: none; 
  border: 1px solid #d1d5da !important;
}

.mon-label:hover {
  text-decoration: none !important;
  outline: none;
}

.mon-label:focus {
  outline: none;
  box-shadow: none;
}

.f-btn {
  padding-left: 12px !important;
  padding-right: 40px !important;
  border-radius: 6px !important;
}
.f-text {
  padding-left: 4px !important;
}
.f-icon {
  padding-right: 0.8rem !important;
}   
.fs-text {
  padding-left: 4px !important;
  padding-right: 14px !important;
}
.py-\[0\.45rem\] {
  padding-top: 0.15rem !important;
  padding-bottom: 0.35rem !important;
}


.report__btn {
  width: auto;
  min-width: 170px;
  padding: 5px;
  height: 40px;
  font-weight: 500;
  color: #ffffff;
  background-color: var(--primary-color);
  border-radius: 8px;
  cursor: pointer;
  border: none !important;
}
.report__btn:hover {
  opacity: 0.8;
  border: none !important;

}

.max-h-dp {
  max-height: 20rem !important;
}

.search-box {
  padding-left: 40px !important;
  border-radius: 6px !important;
}

.search-icon {
  padding-left: 12px !important;
}

.cross-icon {
  top: 2px !important;
  font-size: x-small !important;
  color: #108080 !important;
  right: 3% !important;
  -webkit-text-stroke: 1px #108080 !important;
}

.dashboard-5 {
  margin-top: 4rem !important;
  margin-bottom: 1.25rem !important;
}

.py-5 {
  border-radius: 8px !important;
  padding-bottom: 4rem !important;
  padding-top: 4rem !important;
}

